import { BusinessGoalCategory, BusinessGoalPriority } from '__generated__/api-types-and-hooks'
import { AppPillColorTheme } from 'components/Common/AppPillTextBox/AppPillTextBox.component'
import moment from 'moment'

export const convertEstimationHours = (hours: number): string => {
  const days = Math.floor(hours / 24)
  const remainingHours = hours % 24

  const weeks = Math.floor(days / 7)
  const remainingDays = days % 7
  return `${weeks}w ${remainingDays}d ${remainingHours}h`
}

export const getColorThemeForGoalPriority = (goalPriority: string): AppPillColorTheme => {
  switch (goalPriority) {
    case BusinessGoalPriority.LowPriority:
      return AppPillColorTheme.GREEN
    case BusinessGoalPriority.MediumPriority:
      return AppPillColorTheme.ORANGE
    case BusinessGoalPriority.HighPriority:
      return AppPillColorTheme.RED
    default:
      return AppPillColorTheme.BLUE
  }
}

export const getColorThemeForGoalCategory = (goalCategory: string): AppPillColorTheme => {
  switch (goalCategory) {
    case BusinessGoalCategory.Marketing:
      return AppPillColorTheme.GREEN
    case BusinessGoalCategory.Hr:
      return AppPillColorTheme.ORANGE
    case BusinessGoalCategory.Sales:
      return AppPillColorTheme.RED
    case BusinessGoalCategory.Legal:
      return AppPillColorTheme.BROWN
    case BusinessGoalCategory.Communications:
      return AppPillColorTheme.BLUE
    case BusinessGoalCategory.CustomerService:
      return AppPillColorTheme.LIME
    case BusinessGoalCategory.Finance:
      return AppPillColorTheme.SKY
    case BusinessGoalCategory.InformationTechnology:
      return AppPillColorTheme.YELLOW
    case BusinessGoalCategory.Operations:
      return AppPillColorTheme.PURPLE
    case BusinessGoalCategory.Production:
      return AppPillColorTheme.PURPLE
    case BusinessGoalCategory.ResearchAndDevelopment:
      return AppPillColorTheme.ORANGE
    case BusinessGoalCategory.Strategy:
      return AppPillColorTheme.BROWN
    default:
      return AppPillColorTheme.GREEN
  }
}

export const fieldBooleanOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
]

export const tasksCompletedCount = (goals) => {
  return goals?.reduce((totalCount, goal) => {
    if (goal.plan && goal.plan.milestones) {
      return (
        totalCount +
        goal.plan.milestones.reduce((milestoneCount, milestone) => {
          if (milestone.tasks) {
            const doneCount = milestone.tasks.filter((task) => task.status === 'DONE').length
            return milestoneCount + doneCount
          }
          return milestoneCount
        }, 0)
      )
    }
    return totalCount
  }, 0)
}

export const calculateProgress = (plan) => {
  let totalTasks = 0
  let completedTasks = 0
  if (plan) {
    for (const milestone of plan.milestones) {
      for (const task of milestone.tasks) {
        totalTasks++
        if (task.status === 'DONE') {
          completedTasks++
        }
      }
    }

    const progress = totalTasks === 0 ? 0 : Math.round((completedTasks / totalTasks) * 100)
    return progress
  } else return 0
}

export const allTasksTodo = (plan) => {
  if (plan) {
    for (const milestone of plan.milestones) {
      for (const task of milestone.tasks) {
        if (task.status !== 'TODO') {
          return false
        }
      }
    }
    return true
  }
  return true
}

export const calculateTasksProgress = (tasks) => {
  if (tasks?.length > 0) {
    const totalTasks = tasks?.length
    let statusCounts = {
      DONE: 0,
      TODO: 0,
      IN_PROGRESS: 0,
    }

    tasks.forEach((task) => {
      if (task.status in statusCounts) {
        statusCounts[task.status]++
      }
    })
    if (statusCounts.DONE === totalTasks) return 100
    if (statusCounts.TODO === totalTasks) return 0

    const progress = (statusCounts.IN_PROGRESS / totalTasks) * 100
    return Math.round(progress)
  } else return 0
}

export const getLatestEndDate = (tasks) => {
  if (tasks?.length === 0) return ''

  const latestDate = tasks?.reduce((latest, task) => {
    const endDate = moment(task?.expectedEndDate)
    return endDate.isAfter(latest) ? endDate : latest
  }, moment(0))

  return latestDate?.format('MMMM Do, YYYY')
}
